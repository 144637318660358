import React, { useRef } from 'react'
import custom from '../../assets/custom.webp'
import iot from '../../assets/iot.webp'
import cx from '../../assets/Frame1@2x-100.jpg'
import digital from '../../assets/Frame3@2x-100.jpg'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const WeDo = () => {

  const cardVariants = {
    offscreen: {
      x: 50,
      opacity: 0,
      rotateY: 50
    },
    onscreen: {
      opacity: 1,
      x: 0,
      rotateY: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 3
      }

    }
  };

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.2 }}
      animate={{ x: 0, rotateX: 0 }}
      className='flex flex-col md:mx-24 mx-4 py-14'>
      <h1 className='md:text-4xl text:sm font-poppin-light font-bold text-left md:mb-4 text-left md:text-center'>Unlocking Success: Singtechnologies'</h1>
      <h1 className='md:text-4xl text:sm font-poppin-light font-bold text-left mb-14 text-left md:text-center'> Comprehensive Service Suite</h1>
      <div className='flex md:flex-row flex-col md:justify-center md:space-x-7 space-y-10 md:space-y-0 mb-16 md:mb-24'>
        <motion.div variants={cardVariants} className='flex flex-col space-y-2 md:space-y-1 md:w-1/4 w-full text-center'>
          <Link to={"/ourservices/custom"}>
            <img src={custom} alt="" />
            <p className='font-poppin-bold'>Custom Web & Mobile Development</p>
            <p className='font-poppin-thin'>Creative Web and Mobile R&D</p>
          </Link>
        </motion.div>
        <motion.div variants={cardVariants} className='flex flex-col space-y-2 md:space-y-1 md:w-1/4 w-full text-center'>
          <Link to={"/ourservices/digital"}>
            <img src={digital} alt="" />
            <p className='font-poppin-bold'>Digital Transformation</p>
          </Link>
        </motion.div>
        <motion.div variants={cardVariants} className='flex flex-col space-y-2 md:space-y-1 md:w-1/4 w-full text-center'>
          <Link to={"/ourservices/cx"}>
            <img src={cx} alt="" />
            <p className='font-poppin-bold '>CX/UX </p>
            <p className='font-poppin-bold '>Engineering</p>
          </Link>
        </motion.div>
        <motion.div variants={cardVariants} className='flex flex-col space-y-2 md:space-y-1 md:w-1/4 w-full text-center' >
          <Link to={"/ourservices/iot"}>
            <img src={iot} alt="" />
            <p className='font-poppin-bold'>IoT and Cloud</p>
            <p className='font-poppin-bold'>Computing</p>
          </Link>
        </motion.div>
      </div>
      <div className='text-center'>
        <button className='bg-transparent text-black py-2 px-4 border border-black rounded w-[12rem] font-poppin-bold'><Link to="/ourservices">View All Services</Link></button>
      </div>
    </motion.div>
  )
}

export default WeDo