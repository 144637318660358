import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom';

const Landing = ({ showClass }) => {

  const cardVariants = {
    offscreen: {
      opacity: 0,
      scale: 0.5
    },
    onscreen: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 1
      }
    }
  };

  const allLetters = ["Engineers", "Designers", "Consultants", "Planners", "Technology Strategist"]

  const [letterIndex, setLetterIndex] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setLetterIndex((prevVal) => prevVal < allLetters.length - 1 ? prevVal + 1 : 0);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${showClass} flex flex-col content-start mt-20 lg:mt-16 xl:w-1/2 w-full space-y-8 lg:ps-14 px-6`}>
      <div>
        <h1 className='xl:text-7xl text-5xl text-left xl:text-black text-white font-poppin-black font-black'>We are</h1>
        <h1 viewport={{ once: false, amount: 0.2 }}
          variants={cardVariants}
          initial="offscreen"
          whileInView="onscreen"
          animate={{ opacity: 1 }}
          // bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text 3B83BA
          className='xl:text-7xl text-5xl text-left xl:leading-tight font-bold leading-loose font-poppin-black bg-gradient-to-r from-[#3B83BA] to-[#BCC85B] inline-block text-transparent bg-clip-text'>{allLetters[letterIndex]}</h1>
      </div>

      <p viewport={{ once: false, amount: 0.2 }}
        variants={cardVariants}
        initial="offscreen"
        whileInView="onscreen"
        animate={{ opacity: 1 }}
        className='text-left  w-full xl:w-2/3 font-light text-white xl:text-black font-poppin font-medium'>Welcome to Singtechnologies, where we believe that true success comes from empowering our clients with the right expertise and knowledge. We are not just a tech consultancy; we are your partners in growth, providing you with the tools and guidance you need to succeed in the long term.</p>

      <button viewport={{ once: false, amount: 0.2 }}
        variants={cardVariants}
        initial="offscreen"
        whileInView="onscreen"
        animate={{ opacity: 1 }}
        className='bg-transparent xl:text-black hover:bg-[#4197D2] hover:text-white text-white py-2 px-4 border xl:border-black border-solid border-white rounded w-[8rem] font-poppin-bold m-b-4'><Link to={"/about"}>About Us</Link></button>
    </div>
  )
}

export default Landing