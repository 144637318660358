import React from 'react'
import custom from '../../assets/custom.webp'
import iot from '../../assets/iot_service.png'
import cx from '../../assets/cx.svg'
import digital from '../../assets/Frame3@2x-100.png'
import cyber from '../../assets/cyber.svg'
import it from '../../assets/consultant.svg'
import enterprise from '../../assets/enterprise.svg'
import online from '../../assets/OnlineDietConsultant.png'

import {motion} from 'framer-motion'
import { Link } from 'react-router-dom'

const WeOffer = () => {

    const cardVariants = {
      offscreen: {
        opacity: 0,
        rotateX: 180,
      },
      onscreen: {
        x: 0,
        opacity: 1,
        rotateX: 0,
        transition: {
          type: "spring",
          bounce: 0,
          duration: 2
        }
      }
    };
  
    return (
      <div 
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.2 }}
      animate={{ x: 0 }}
      className='flex flex-col mx-14 pb-14 mt-8'>
        <h1 className='text-4xl font-poppin-light font-bold text-center mb-14'>What we offer</h1>
        <div className='flex flex-col space-y-2 xl:items-center'>
            <div className='flex xl:flex-row flex-col space-y-7 xl:space-y-0 xl:space-x-7 mb-10'>
                <div className='flex flex-col md:flex-row md:justify-between xl:space-x-7 w-full'>
                    <div variants={cardVariants} className='flex flex-col space-y-1'>
                        <img src={custom} className='max-w-80' alt="" />
                        <div className='flex flex-row justify-center'>
                            <div className='flex flex-col space-y-1'>
                                <Link to={"/ourservices/custom"}>
                                    <p className='font-poppin-bold text-left'>Custom Web & Mobile Development</p>
                                    {/* <p className='font-poppin-bold text-left'>Mobile Development</p> */}
                                </Link>
                            </div>
                            {/* <div className='mt-1'>
                                <button className='bg-transparent text-black text-xs py-1 px-1 border border-black rounded w-[5rem] font-poppin-bold'><Link to={"/ourservices/custom"}>Learn More</Link></button>
                            </div> */}
                        </div>
                    </div>
                    <div variants={cardVariants} className='flex flex-col space-y-1'>
                        <img src={digital} alt="" className='max-w-80'/>
                        <div className='flex flex-row justify-center'>
                            <div className='flex flex-col space-y-1'>
                                <Link to={"/ourservices/digital"}>
                                    <p className='font-poppin-bold text-left'>Digital Transformation</p>
                                    {/* <p className='font-poppin-bold text-left'>Transformation</p> */}
                                </Link>
                            </div>
                            {/* <div className='mt-1'>
                                <button className='bg-transparent text-black text-xs py-1 px-1 border border-black rounded w-[5rem] font-poppin-bold'>Learn More</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row md:justify-between xl:justify-normal xl:space-x-7 w-full'>
                    <div variants={cardVariants} className='flex flex-col space-y-4'>
                        <img src={cx} alt="" className='max-w-[300px]'/>
                        <div className='flex flex-row justify-center'>
                            <div className='flex flex-col space-y-1'>
                                <Link to={"/ourservices/cx"}>
                                    <p className='font-poppin-bold text-left'>CX/UX Engineering</p>
                                    {/* <p className='font-poppin-bold text-left'>Engineering</p> */}
                                </Link>
                            </div>
                            {/* <div className='mt-1'>
                                <button className='bg-transparent text-black text-xs py-1 px-1  border border-black rounded w-[5rem] font-poppin-bold'><Link to={"/ourservices/cx"}>Learn More</Link></button>
                            </div> */}
                        </div>
                    </div>
                    <div variants={cardVariants} className='flex flex-col space-y-1'>
                        <img src={iot} alt="" className='max-w-80' />
                        <div className='flex flex-row justify-center'>
                            <div className='flex flex-col space-y-1'>
                                <Link to={"/ourservices/iot"}>
                                    <p className='font-poppin-bold text-left'>IoT and Cloud Computing</p>
                                    {/* <p className='font-poppin-bold text-left'>Computing</p> */}
                                </Link>
                            </div>
                            {/* <div className='mt-1'>
                                <button className='bg-transparent text-black text-xs py-1 px-1 border border-black rounded w-[5rem] font-poppin-bold'><Link to={"/ourservices/iot"}>Learn More</Link></button>
                            </div> */}
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
        <div className='flex flex-col space-y-2 xl:items-center'>
            <div className='flex xl:flex-row flex-col space-y-7 xl:space-y-0 xl:space-x-7 mb-10'>
                <div className='flex flex-col md:flex-row md:justify-between xl:justify-normal xl:space-x-7'>
                    <div variants={cardVariants} className='flex flex-col space-y-1'>
                        <img src={cyber} alt="" className='max-w-[300px]' />
                        <div className='flex flex-row justify-center'>
                            <div className='flex flex-col space-y-1'>
                                <Link to={"/ourservices/cyber"}>
                                    <p className='font-poppin-bold text-left'>Cyber Security</p>
                                    {/* <p className='font-poppin-bold text-left'>Security</p> */}
                                </Link>
                            </div>
                            {/* <div className='mt-1'>
                                <button className='bg-transparent text-black text-xs py-1 px-1 border border-black rounded w-[5rem] font-poppin-bold'><Link to={"/ourservices/cyber"}>Learn More</Link></button>
                            </div> */}
                        </div>
                    </div>
                    <div variants={cardVariants} className='flex flex-col space-y-1'>
                        <img src={it} alt="" className='max-w-[300px]'/>
                        <div className='flex flex-row justify-center'>
                            <div className='flex flex-col space-y-1'>
                                <Link to={"/ourservices/it"}>
                                    <p className='font-poppin-bold text-left'>IT Consultancy</p>
                                    {/* <p className='font-poppin-bold text-left'>Consultancy</p> */}
                                </Link>
                            </div>
                            {/* <div className='mt-1'>
                                <button className='bg-transparent text-black text-xs py-1 px-1 border border-black rounded w-[5rem] font-poppin-bold'><Link to={"/ourservices/it"}>Learn More</Link></button>
                            </div> */}
                        </div> 
                    </div>
                </div>
                <div className='flex flex-col md:flex-row md:justify-between xl:justify-normal xl:space-x-7'>
                    <div variants={cardVariants} className='flex flex-col space-y-1'>
                        <img src={enterprise} alt="" className='max-w-[300px]'/>
                        <div className='flex flex-row justify-center'>
                            <div className='flex flex-col space-y-1'>
                                <Link to={"/ourservices/enterprise"}>
                                    <p className='font-poppin-bold text-left'>Enterprise Service</p>
                                    {/* <p className='font-poppin-bold text-left'>Service</p> */}
                                </Link>
                            </div>
                            {/* <div className='mt-1'>
                                <button className='bg-transparent text-black text-xs py-1 px-1 border border-black rounded w-[5rem] font-poppin-bold'><Link to={"/ourservices/enterprise"}>Learn More</Link></button>
                            </div> */}
                        </div>
                    </div>
                    {/* <div variants={cardVariants} className='flex flex-col space-y-1'>
                        <img src={online} alt="" />
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col space-y-1'>
                                <p className='font-poppin-bold text-left'>Online Diet</p>
                                <p className='font-poppin-bold text-left'>Consultant</p>
                            </div>
                            <div className='mt-1'>
                                <button className='bg-transparent text-black text-xs py-1 px-1 border border-black rounded w-[5rem] font-poppin-bold'><Link to={"/ourservices/diet"}>Learn More</Link></button>
                            </div>
                        </div>
                    </div> */}
                </div>
                
                
            </div>
        </div>
      </div>
    )
  }
  
  export default WeOffer