import React from 'react'
import doublequote from '../../assets/doublequote.svg'

const AboutUsLanding = () => {
  return (
    <div className='flex flex-col xl-mb-14  px-6 lg:ps-14'>
      <div className='relative xl:p-14 flex flex-col mb-32 xl:mb-14'>
        <img className="absolute right-0 top-36 xl:right-52 xl:top-44" src={doublequote} alt="" />
        <h1 className='font-poppin-light font-bold text-5xl text-white mt-20 mb-20'>About Us</h1>
        <h2 className='font-poppin-bold text-lg xl:text-xl text-white mb-6'>Dedication to Success</h2>
        <p className='font-poppin-light text-md xl:text-lg text-white mb-6 w-full xl:w-2/3'>Improve efficiency, leverage tech, and provide better customer experiences with the modern technology services available all overthe world. Our skilled personnel, utilizing the latest processingsoftware, combined with decades of experience.</p>
        <p className='font-poppin-bold font-bold text-sm text-white'>Co-Founder- Manoj Kumar Rai (CEO), Narmeet Singh (CTO)</p>
      </div>
    </div>
  )
}

export default AboutUsLanding