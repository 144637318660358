import React from 'react'
import about from '../../assets/aboutus.png'
import { Link } from 'react-router-dom'

const QualityLanding = () => {
  return (
    <div className='absolute left-12 xl:left-24 bottom-6 bg-slate-100 py-6 xl:px-12 px-6 w-[250px] md:w-[460px] h-[400px] md:h-[300px] shadow-xl'>
      <div className='flex flex-col space-y-4'>
        <h1 className='font-poppin-light text-3xl text-left '>Quality Assured</h1>
        <p className='font-poppin-thin text-md md:lg'>For inquiries, collaborations, or any assistance, please don't hesitate to reach out. Our team is here to help. Contact us via phone or email, and we'll respond promptly. Thank you for considering us.</p>
        <button className='bg-transparent text-black text-m py-1 px-1 border border-black border-solid rounded w-[8rem] font-poppin-medium'><Link to={'/getintouch'}>Get In Touch</Link></button>
      </div>
    </div>
  )
}

export default QualityLanding