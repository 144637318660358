import React from 'react'
import fb from '../../assets/foot-fb.svg'
import whatsapp from '../../assets/foot-what.svg'
import linkedin from '../../assets/foot-linkedin.svg'
import fb_s from '../../assets/fb_s.png'
import whatsapp_s from '../../assets/whatsapp_s.png'
import linkedin_s from '../../assets/linkedin_s.png'
import bigwhatsapp from '../../assets/whatsapp_2.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='border bg-[#3a86bd] w-full items-start md:px-10 bg-no-repeat bg-cover text-white border-gray-300 border-t flex flex-col'>
      <div className='flex flex-col w-2/4 lg:flex-row mt-12 items-start justify-between space-y-8 lg:space-y-0'>
        <div className='p-2 flex flex-col space-y-4'>
          <h1 className='font-bold lg:text-mde text-md'>Our Services</h1>
          <div className='flex md:flex-row lg:text-xs text-md flex-col space-y-4 lg:space-y-0 lg:space-x-2 gap-x-4'>
            <div className='flex flex-col text-left space-y-2'>
              <span className='font-poppin-bold'><Link to={"/ourservices/custom"}>Custom Web & Mobile Development</Link></span>
              <span className='font-poppin-bold'><Link to={"/ourservices/digital"}>Digital Transformation</Link></span>
              <span className='font-poppin-bold'><Link to={"/ourservices/cx"}>CX/UX Engineering</Link></span>
              <span className='font-poppin-bold'><Link to={"/ourservices/iot"}>IoT and Cloud Computing</Link></span>
              <span className='font-poppin-bold'><Link to={"/ourservices/cyber"}>Cyber Security</Link></span>
              <span className='font-poppin-bold'><Link to={"/ourservices/it"}>IT Consultancy</Link></span>
              <span className='font-poppin-bold'><Link to={"/ourservices/enterprise"}>Enterprise Services</Link></span>
            </div>
            {/* <div className='flex flex-col text-left'> */}
              
              {/* <span className='font-poppin-bold'><Link to={"/ourservices/diet"}>Online Diet Consultant</Link></span> */}
            {/* </div> */}
          </div>
        </div>
        <div className='p-2 flex flex-col space-y-2 text-md'>
          <h1 className='font-poppin-bold text-left font-bold lg:text-md'>Contact Us</h1>
          <div className='flex flex-col text-left lg:text-xs'>
            <span className='font-poppin-bold'>info@singtecs.com</span>
            <span className='font-poppin-bold'>8 Ubi Rd 2 #07-22, Zervex, Singapore</span>
          </div>
        </div>
        <div className='p-2 flex flex-col space-y-4 invisible md:visible'>
          <h1 className='font-poppin-bold text-left font-bold lg:text-md text-md'>Follow Us</h1>
          <div className='flex flex-row space-x-4'>
            <Link to="https://www.facebook.com/Singtechnologies">
              <img src={fb} alt="" style={{ filter: "invert(1)" }} />
            </Link>
            <Link to="https://www.linkedin.com/company/singtechnologies/">
              <img src={linkedin} alt="" style={{ filter: "invert(1)" }} />
            </Link>
            <Link to="https://wa.link/1z0rwi">
              <img src={whatsapp} alt="" style={{ filter: "invert(1)" }} />
            </Link>
          </div>
        </div>
      </div>
      <div className='md:invisible visible md:m-10 mb-28 underline self-center w-full text-center text-xs'>
            <Link to="/privacy">Privacy Policy</Link>
        </div>
      <div className='relative w-full  invisible md:visible'>
        <div className='fixed bottom-16 right-10 w-[100px] flex justify-end items-center'>
          {/* <h2 className='text-black text-xs lg:text-xl'>Need help?</h2> */}
          {/* <h2 className='font-poppin-bold text-bold text-black px-4 text-xs lg:text-xl'>Chat with us</h2> */}
          <Link to="https://wa.link/1z0rwi">
            <img src={bigwhatsapp} alt="" />
          </Link>
        </div>
        <div className='invisible md:visible md:m-10 mb-4 underline self-center w-full text-center text-xs'>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
      <div className='relative w-full'>
        <div className='fixed bottom-0 visible sm:border-none md:invisible w-full bg-white h-[90px]'>
          <div className='flex flex-row space-x-16 justify-center my-4'>
            <div className='flex flex-col justify-items-center '>
              <Link to="https://www.facebook.com/Singtechnologies"  className='w-[40px] ms-2 '>
                <img src={fb_s} alt="" className='w-[40px]'/>
              </Link>
              <p className='text-black text-xs'>Facebook</p>
            </div>
            <div className='flex flex-col justify-items-center'>
              <Link to="https://www.linkedin.com/company/singtechnologies/"  className='ms-1 w-[40px]'>
                <img src={linkedin_s} alt="" className='w-[40px]'/>
              </Link>
              <p className='text-black text-xs'>Linkedin</p>
            </div>
            <div className='flex flex-col justify-items-center'> 
            <Link to="https://wa.link/1z0rwi" className='w-[40px] ms-2 '>
              <img src={whatsapp_s} alt="" className='w-[40px]'/>
            </Link>
              <p className='text-black text-xs'>
                Whatsapp
              </p>
            </div>
          </div>
        </div>
      </div>
      
    </div>

  )
}

export default Footer