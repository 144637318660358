import React from 'react'
import './Header.css'
import logo from '../../assets/s-logo.svg'
import logo_s from '../../assets/logo-s.svg'
import whiteLogo from '../../assets/s-logo-white.svg'
import linkedin from '../../assets/linkedin.svg'
import facebook from '../../assets/facebook.svg'
import whatsapp from '../../assets/whatsapp.svg'
import DropDownMenu from '../../components/DropDownMenu'
import { useState,useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import burger from  '../../assets/burger-menu.svg'
import MobileDropDown from '../../components/MobileDropDown'

const Header = ({setShow}) => {
  
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLogoWhite, setIsLogoWhite] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [isMobileMenu, setIsMobileMenu] = useState(false)

  useEffect(() => {
    handleMouseEnter(isMenu)
  }, [isMenu])
  
  const handleMouseEnter = ( value ) => {
    setIsDropdownVisible(value)
  }
  const handleMouseNavEnter = ( value ) => {
    setIsLogoWhite(value)
  }

  useEffect(() => {
    handleOnClick(isMobileMenu)
  }, [isMobileMenu])
  
  const handleOnClick = (value) => {
    setIsMobileMenu(value)
  }
  return (
    <div className="hover:bg-gradient-to-r from-[#3B83BA] to-[#BCC85B] lg:ps-14 px-6" onMouseEnter={()=>handleMouseNavEnter(true)} onMouseLeave={()=>{handleMouseNavEnter(false); handleMouseEnter(false)}}>
      <div className="bg-no-repeat flex flex-row xl:items-center justify-between border-solid border-b border-b-white py-5 xl:h-auto">
        <div className='text-left hidden lg:block w-1/3'>
          <Link to={"/"}>
            {
              isLogoWhite ? <img src={whiteLogo} alt="logo" className='object-scale-down' width={200}/> : <img src={logo} alt="logo" className='object-scale-down' width={200}/>
            }
          </Link>
        </div>
        <div className='text-left w-1/3 lg:hidden'>
          <Link to={"/"}>
            {
              isLogoWhite ? <img src={whiteLogo} alt="logo" className='object-scale-down' width={200}/> : <img src={logo_s} alt="logo" className='object-scale-down' width={200}/>
            }
          </Link>
        </div>
        <div className='xl:inline hidden w-2/4'>
          <div className="flex flex-row justify-end lg:pe-14 gap-x-10 ">
            <NavLink to={"/about"} className={({ isActive }) => (isActive ? "font-poppin-book cursor-pointer text-blue-500" : "text-white font-poppin-book cursor-pointer")}>About Us</NavLink>

            <NavLink to={"/ourservices"} className={({ isActive }) => (isActive ? "relative font-poppin-book cursor-pointer text-blue-500" : "relative text-white font-poppin-book cursor-pointer")} onMouseEnter={()=>handleMouseEnter(true)}>Our Services</NavLink>
            <DropDownMenu show={isDropdownVisible}/>

            <NavLink to={"/getintouch"} className={({ isActive }) => (isActive ? "font-poppin-book cursor-pointer text-blue-500" : "text-white font-poppin-book cursor-pointer")}>Get a free quote</NavLink>
            <Link to="https://www.facebook.com/Singtechnologies">
              <img src={facebook} alt="" className='xl:object-scale-down xl:inline hidden'/>
            </Link>
            <Link to="https://wa.link/1z0rwi">
              <img src={whatsapp} alt="" className='xl:object-scale-down xl:inline hidden'/>
            </Link>
            <Link to="https://www.linkedin.com/company/singtechnologies/">
              <img src={linkedin} alt="" className='xl:object-scale-down xl:inline hidden'/>
            </Link>
          </div>
        </div>
        <div className='xl:hidden'>
          <img src={burger} alt="" className='object-scale-down cursor-pointer' onClick={()=> handleOnClick(!isMobileMenu)}/>
        </div>
        <MobileDropDown showMenu={isMobileMenu} setOnClick={handleOnClick}/>
      </div>
    </div>
  )
}

export default Header