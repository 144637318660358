import React from 'react'
import './Clients.css'
import audi from '../../assets/client/Audi-logo-8508C17BED-seeklogo.svg'
import bca from '../../assets/client/bca-logo.svg'
import htech from '../../assets/client/HTech_Logo_vertical_fullcolour.svg'
import ihc from '../../assets/client/IHC-Logo-Reverse-1024x396-1_edited.svg'
import keppel from '../../assets/client/keppel.svg'
// import auth from '../../assets/auth.svg'
import nec from '../../assets/client/NEC_logo.svg'
import opti from '../../assets/client/OPTIMUM SOLUTIONS.svg'
import raptor from '../../assets/client/RAPTOR.svg'
import tyho from '../../assets/client/tyho.png'
import pistill from '../../assets/client/pistill.svg'
import pn from '../../assets/client/pn.svg'
import kinn from '../../assets/client/kinn.svg'
import mandai from '../../assets/client/Mandai-New-Logo.svg'
import alpsoft from '../../assets/client/alphsoftlogo.svg'
import { motion, animate } from 'framer-motion'
// import { Carousel } from 'antd';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const rightVarient = (elem) => {
  return <motion.div initial={{ opacity: 0, x: -300 }}
    className='xl:w-1/6 md:w-1/5 sm:w-1/4 max-h-[300px]'
    whileInView={{
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 3
      }
    }}
    viewport={{ once: true }}>
    {elem}
  </motion.div>
};

const leftVarient = (elem) => {
  return <div
    className='xl:w-3/6 md:w-3/4 sm:w-[120px] max-w-[300px] md:max-w-none max-h-[300px]'
    whileInView={{
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 3
      }
    }}
    viewport={{ once: true }}>
    {elem}
  </div>
}
// const leftVarient = (elem) => {
//   return <div
//     className='max-w-none'
//     viewport={{ once: true }}>
//     {elem}
//   </div>
// }

const Clients = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5000, min: 4000 },
      items: 5,
      partialVisibilityGutter: 40
    },
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 10
    }
  };
  return (
    <div className='bg-world-image bg-auto bg-right-top bg-no-repeat shadow-md xl:mx-24 overflow-hidden'>
      <div className='flex flex-col md:p-14 p-4 space-y-16 lg:items-center my-4'>
        <h1 className='xl:text-4xl md:text-2xl text-md font-poppin font-bold w-full text-center'>Our reputation is built on creating great outcomes for our clients</h1>
        <div className='flex flex-col w-full '>
          <div className='flex space-x-16x group'> 
              <div className='flex space-x-16 animate-loop-scroll'>
                <img src={audi} className = "max-h-[50px] max-w-none" alt="" />
                <img src={kinn} className = "max-h-[50px] max-w-none" alt="" />
                <img src={ihc} className = "max-h-[50px] max-w-none" alt="" />
                <img src={opti} className = "max-h-[50px] max-w-none" alt="" />
                <img src={nec} className = "max-h-[50px] max-w-none" alt="" />
                <img src={bca} className = "max-h-[50px] max-w-none" alt="" />
                <img src={alpsoft}  className = "max-h-[50px] max-w-none" alt=""/>
                <img src={mandai} className = "max-h-[50px] max-w-none" alt="" />
                <img src={htech} className = "max-h-[50px] max-w-none" alt="" />
                <img src={keppel} className = "max-h-[50px] max-w-none" alt="" />
                <img src={raptor}  className = "max-h-[50px] max-w-none" alt="" />
                <img src={pn} className = "max-h-[50px] max-w-none" alt="" />
                <img src={tyho} className = "max-h-[50px] max-w-none" alt="" />
                <img src={pistill} className = "max-h-[50px] max-w-none" alt="" />
            </div>
            <div className='flex space-x-16 animate-loop-scroll' aria-hidden="true">
                <img src={audi} className = "max-h-[50px] max-w-none" alt="" />
                <img src={kinn} className = "max-h-[50px] max-w-none" alt="" />
                <img src={ihc} className = "max-h-[50px] max-w-none" alt="" />
                <img src={opti} className = "max-h-[50px] max-w-none" alt="" />
                <img src={nec} className = "max-h-[50px] max-w-none" alt="" />
                <img src={bca} className = "max-h-[50px] max-w-none" alt="" />
                <img src={alpsoft}  className = "max-h-[50px] max-w-none" alt=""/>
                <img src={mandai} className = "max-h-[50px] max-w-none" alt="" />
                <img src={htech} className = "max-h-[50px] max-w-none" alt="" />
                <img src={keppel} className = "max-h-[50px] max-w-none" alt="" />
                <img src={raptor}  className = "max-h-[50px] max-w-none" alt="" />
                <img src={pn} className = "max-h-[50px] max-w-none" alt="" />
                <img src={tyho} className = "max-h-[50px] max-w-none" alt="" />
                <img src={pistill} className = "max-h-[50px] max-w-none" alt="" />
            </div>
          </div>

          {/* <Carousel responsive={responsive} showDots={false} partialVisible={true} autoPlaySpeed={100} infinite={true} autoPlay={true} arrows={false}> */}
            
            {/* {leftVarient(<img src={audi} alt="" />)}
            {leftVarient(<img src={ihc} alt="" />)}
            {leftVarient(<img src={opti} alt="" />)}
            {leftVarient(<img src={nec} alt="" />)}
            {leftVarient(<img src={bca} alt="" />)}
            {leftVarient(<img src={alpsoft} alt="" />)}
            {leftVarient(<img src={mandai} alt="" />)}
            {leftVarient(<img src={htech} alt="" />)}
            {leftVarient(<img src={keppel} alt="" />)}
            {leftVarient(<img src={raptor} alt="" />)}
            {leftVarient(<img src={kinn} alt="" />)}
            {leftVarient(<img src={pn} alt="" />)}
            {leftVarient(<img src={tyho} alt="" />)}
            {leftVarient(<img src={pistill} alt="" />)} */}
          {/* </Carousel> */}
        </div>
      </div>
    </div >
  )
}

export default Clients